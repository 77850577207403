/* Estilos para el carrito flotante */
#cart {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

#cart:hover {
  background-color: #aaa;
}

#cart.opened {
  width: 300px;
  height: auto;
}

#cart.opened ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#cart.opened li {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

#cart.opened li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

#cart.opened button {
  margin-left: 10px;
}

/* Estilos para los productos */
ul.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.products li {
  border: 2px solid #ccc;
  border-radius: 2px;
  padding: 10px;
}

ul.products li h3 {
  margin-top: 0;
}

ul.products li button {
  background-color: green;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Estilos para la barra de filtros */
#filterInput {
  width: 50%;
  background-color: #f2f2f2;
  padding: 7px;
  margin: 10px;
}

/* Estilos para el input de fecha */
#fechaAddInventario {
  margin: 10px;
}
.cart-count {
  position: absolute;
  top: 10px; /* Ajusta esta propiedad para establecer la distancia desde la parte superior */
  right: 10px; /* Ajusta esta propiedad para establecer la distancia desde la derecha */
  background-color: red; /* Color de fondo del contador */
  color: white; /* Color del texto del contador */
  border-radius: 50%; /* Esto hace que el contador sea circular */
  padding: 0px 4px; /* Espaciado interno del contador */
  font-size: 14px; /* Tamaño del texto del contador */
  z-index: 2;
}
