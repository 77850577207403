/* styles.css */
.contenedor {
  display: flex;
}
.columna {
  padding: 20px;
}

.columna-30 {
  flex-basis: 30%;
  background-color: #f2f2f2;
  font-size: 20px;
}

.columna-70 {
  flex-basis: 70%;
}
.form-container {
  background-color: #e3f2fd;
  padding: 20px;
}

.form-container label {
  display: block;
  margin-bottom: 10px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.form-container button {
  background-color: #3cc543;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0bb914;
}

/* Estilos para la lista de productos */
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 2px;
  border: 1px solid;
}
.dadodebaja{
  align-items: start;
  text-align: start;
}