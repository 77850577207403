.producto-lista {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Divide en tres columnas */
  gap: 10px; /* Espacio entre elementos */
}

.producto-item {
  background-color: #f5f5f5; /* Color de fondo del elemento */
  padding: 10px;
  border: 1px solid #ddd; /* Borde alrededor del elemento */
  border-radius: 5px; /* Bordes redondeados */
  display: flex;
  flex-direction: column;
}

.nombre {
  font-weight: bold; /* Texto en negrita para el nombre del producto */
}

/* Estilos adicionales según sea necesario */
