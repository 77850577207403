/* Estilos generales */

/* Resetear los estilos por defecto del navegador */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Establecer estilos de fuente y tamaño base */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.sidebar {
  background-color: #f8f8f8;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  transition: transform 0.3s ease-in-out;
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar.closed {
  transform: translateX(-200px);
}

.menu-toggle {
  display: none;
}

.close-menu {
  display: none;
}

.content {
  transition: transform 0.3s ease-in-out;
  padding-left: 0;
}
.corner-icon {
  display: none;
}

@media (min-width: 769px) {
  .sidebar {
    transform: translateX(0);
  }

  .content {
    padding-left: 200px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 50%;
    transform: translateX(-100%);
  }

  .menu-toggle {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .menu-toggle img {
    width: 20px;
  }

  .close-menu {
    display: flex;
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .close-menu img {
    width: 20px;
  }
  .corner-icon {
    display: block;
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
  }

  .corner-icon img {
    width: 20px;
  }
}
