* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

/* Estilos para el carrito flotante */
#cart {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.cart-items {
  display: none;
  position: fixed;
  top: 50px;
  right: 20px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  list-style: none;
}

.cart-items.open {
  display: block;
}

/* Estilos para los productos */
.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  list-style: none;
}

.product {
  border: 2px solid #000;
  padding: 10px;
}

.product h3 {
  margin-top: 0;
}

.product button {
  color: #fff;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
.product .add-button{
  background-color: green;
}
.product .del-button{
  background-color: red;
}

/* Estilos para los filtros */
#filterInput {
  width: 50%;
  margin-bottom: 20px;
}

.filters label {
  font-weight: bold;
}
.cart-count {
  position: absolute;
  top: 10px; /* Ajusta esta propiedad para establecer la distancia desde la parte superior */
  right: 10px; /* Ajusta esta propiedad para establecer la distancia desde la derecha */
  background-color: red; /* Color de fondo del contador */
  color: white; /* Color del texto del contador */
  border-radius: 50%; /* Esto hace que el contador sea circular */
  padding: 0px 4px; /* Espaciado interno del contador */
  font-size: 14px; /* Tamaño del texto del contador */
  z-index: 2;
}